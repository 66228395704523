"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auditPatient = [{
        path: '/audit-patient',
        meta: {
            title: "患者审核"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/audit-patient/list.vue"); }); }
    }];
exports.default = auditPatient;
