<template>
  <h-container isTable title="患者审核列表">
    <div>
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="名称" prop="title">
          <el-input v-model="model.title" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="model.status" placeholder="请选择审核状态" style="width: 240px">
            <h-dict dictKey="audit_type"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reload(load)">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="list" stripe style="width: 100%">
      <el-table-column prop="id" label="编号" header-align="center" align="center" width="70" />
      <el-table-column label="姓名/昵称" header-align="center" align="center" width="200">
        <template #default="scope">
          <span>{{scope.row.realname || scope.row.nickname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="team_name" label="小组名称" header-align="center" align="center" width="200" />
      <el-table-column prop="project_name" label="项目名称" header-align="center" align="center" width="100" />
      <el-table-column prop="doctor_nickname" label="医生姓名" header-align="center" align="center" width="100" />
      <el-table-column label="提交时间" header-align="center" align="center" width="200">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column label="审核时间" header-align="center" align="center" width="200">
        <template #default="scope">
          <span v-if="scope.row.createtime == scope.row.updatetime">--</span>
          <span v-else>
            {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" header-align="center" align="center" width="100">
        <template #default="scope">
          <h-dict mode="text" dictKey="audit_type" v-model="scope.row.status"/>
        </template>
      </el-table-column>
      <el-table-column prop="memo" label="拒绝原因" header-align="center" align="center" />
      <el-table-column prop="memo" label="操作" header-align="center" align="center" width="200" fixed="right">
        <template #default="scope">
          <el-button type="primary" v-if="scope.row.status == 'hidden'" @click="auditEvent(scope.row, 'audit')" link>审核</el-button>
          <el-button type="primary" v-else link @click="auditEvent(scope.row, 'detail')">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
    <audit-patient-dialog ref="auditDialogRef" @on-load="load"/>
  </h-container>
</template>

<script lang="ts" setup>
import {ref, onMounted} from "vue";
import {useApp} from "@hview/hview-vue3/packages/hooks";
import {usePagination, useTimeFormat} from "@hview/hview-vue3/packages";
import {getApproveListApi} from "@/api/kh";
import {AuditPatientDialog} from "@/components/views/kh";
const {loading} = useApp();
const {updatePage, getPagination, totalRow, update, page, reload} = usePagination();
const {timeFormat} = useTimeFormat();

/**
 * 表单数据
 */
const model = ref({});

/**
 * 列表数据
 */
const list = ref([]);

/**
 * 审核弹窗
 */
const auditDialogRef = ref();

/**
 * 表单ref
 */
const formRef = ref();

/**
 * @description 加载数据
 */
const load = () => {
  loading.value = true;
  getApproveListApi({...getPagination(), ...model.value}).then((res: any) => {
    const {data} = res;
    updatePage(data);
    list.value = data.data;
  }).finally(() => {
    loading.value = false;
  });
}

const resetForm = () => {
  formRef.value.resetFields();
  reload(load);
}

/**
 * @description 打开审核弹窗
 */
const auditEvent = (data: any, type: string) => {
  auditDialogRef.value.open(data.id, data.user_id, type);
}


/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}

onMounted(() => {
  load();
});


</script>

<style scoped>

</style>