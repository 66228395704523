"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "m-t-20 m-b-20" };
var _hoisted_2 = { key: 0 };
var _hoisted_3 = { key: 1 };
var _hoisted_4 = { key: 1 };
var vue_3 = require("vue");
var hooks_1 = require("@hview/hview-vue3/packages/hooks");
var kh_1 = require("@/api/kh");
var packages_1 = require("@hview/hview-vue3/packages");
exports.default = (0, vue_1.defineComponent)({
    __name: 'audit-dialog',
    emits: ["on-load"],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var _b = (0, hooks_1.useApp)(), visible = _b.visible, loading = _b.loading;
        var getImagePath = (0, hooks_1.useImage)().getImagePath;
        var emits = __emit;
        /**
         * 审核意见ref
         */
        var reasonRef = (0, vue_3.ref)();
        var modelType = (0, vue_3.ref)("");
        /**
         * 表单ref
         */
        var formRef = (0, vue_3.ref)();
        /**
         *  步骤条选中
         */
        var stepActive = (0, vue_3.ref)(0);
        var model = (0, vue_3.ref)({
            id: "",
            status: "",
            projectId: "",
            reason: "",
            teamId: ""
        });
        /**
         * @description 审核图片
         */
        var auditImages = (0, vue_3.ref)([]);
        /**
         * @description 监听审核状态变化
         */
        (0, vue_3.watch)(function () { return model.value.status; }, function (value) {
            if (value !== "rejected") {
                reasonRef.value.clearValidate();
            }
        });
        (0, vue_3.watch)(visible, function (value) {
            if (!value) {
                if (formRef.value) {
                    formRef.value.resetFields();
                }
                stepActive.value = 0;
            }
        });
        /**
         * 校验规则
         */
        var rules = (0, vue_3.ref)({
            projectId: [{ required: true, message: "请选择项目", trigger: "change" }],
            teamId: [{ required: true, message: "请选择中心", trigger: "change" }],
            status: [{ required: true, message: "请选择状态", trigger: "change" }]
        });
        /**
         * 详细信息
         */
        var info = (0, vue_3.ref)({});
        /**
         * @description 提交数据至后台
         */
        var submit = function () {
            formRef.value.validate(function (valid) {
                if (valid) {
                    loading.value = true;
                    (0, kh_1.certificationApproveApi)(model.value).then(function () {
                        packages_1.Notice.success("提交成功");
                        visible.value = false;
                        emits("on-load");
                    }).finally(function () {
                        loading.value = false;
                    });
                }
            });
        };
        /**
         * 项目列表
         */
        var projectList = (0, vue_3.ref)([]);
        /**
         * 小组列表
         */
        var teamList = (0, vue_3.ref)([]);
        /**
         * @description 获取team列表
         */
        var getTeamList = function () {
            (0, kh_1.getTeamListByProjectApi)({ projectId: model.value.projectId }).then(function (res) {
                var data = res.data;
                teamList.value = data;
            });
        };
        var selectEvent = function () {
            model.teamId = "";
            getTeamList();
        };
        /**
         * @description 加载详情
         */
        var load = function () {
            loading.value = true;
            Promise.all([(0, kh_1.getCertificationInfoApi)(model.value), (0, kh_1.getProjectListApi)()]).then(function (arr) {
                var certificationInfo = arr[0].data;
                model.value.projectId = certificationInfo.kh_project_id;
                if (model.value.projectId) {
                    getTeamList();
                }
                var project = arr[1].data;
                info.value = certificationInfo;
                var splitImage = certificationInfo.images.split(",");
                auditImages.value = [];
                splitImage.forEach(function (item) {
                    auditImages.value.push(getImagePath(item));
                });
                projectList.value = project;
            }).finally(function () {
                loading.value = false;
            });
        };
        /**
         * @description 打开弹窗
         */
        var open = function (id, type) {
            model.value.id = id;
            visible.value = true;
            modelType.value = type;
            load();
        };
        __expose({
            open: open
        });
        return function (_ctx, _cache) {
            var _component_el_step = (0, vue_2.resolveComponent)("el-step");
            var _component_el_steps = (0, vue_2.resolveComponent)("el-steps");
            var _component_h_text = (0, vue_2.resolveComponent)("h-text");
            var _component_el_descriptions_item = (0, vue_2.resolveComponent)("el-descriptions-item");
            var _component_h_dict = (0, vue_2.resolveComponent)("h-dict");
            var _component_el_image = (0, vue_2.resolveComponent)("el-image");
            var _component_el_descriptions = (0, vue_2.resolveComponent)("el-descriptions");
            var _component_el_option = (0, vue_2.resolveComponent)("el-option");
            var _component_el_select = (0, vue_2.resolveComponent)("el-select");
            var _component_el_form_item = (0, vue_2.resolveComponent)("el-form-item");
            var _component_el_radio_group = (0, vue_2.resolveComponent)("el-radio-group");
            var _component_el_input = (0, vue_2.resolveComponent)("el-input");
            var _component_el_form = (0, vue_2.resolveComponent)("el-form");
            var _component_el_button = (0, vue_2.resolveComponent)("el-button");
            var _component_el_dialog = (0, vue_2.resolveComponent)("el-dialog");
            var _directive_loading = (0, vue_2.resolveDirective)("loading");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_dialog, {
                "close-on-click-modal": false,
                title: modelType.value == 'audit' ? '审核' : '审核详情',
                modelValue: (0, vue_2.unref)(visible),
                "onUpdate:modelValue": _cache[15] || (_cache[15] = function ($event) { return ((0, vue_2.isRef)(visible) ? (visible).value = $event : null); }),
                width: "750"
            }, {
                footer: (0, vue_2.withCtx)(function () { return [
                    (modelType.value == 'audit')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, [
                            (0, vue_2.createVNode)(_component_el_button, {
                                link: "",
                                onClick: _cache[11] || (_cache[11] = function ($event) { return (visible.value = false); })
                            }, {
                                default: (0, vue_2.withCtx)(function () { return [
                                    (0, vue_2.createTextVNode)("取消")
                                ]; }),
                                _: 1
                            }),
                            (stepActive.value == 1)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                    key: 0,
                                    type: "primary",
                                    onClick: _cache[12] || (_cache[12] = function ($event) { return (stepActive.value = 0); })
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)("上一步")
                                    ]; }),
                                    _: 1
                                }))
                                : (0, vue_2.createCommentVNode)("", true),
                            (stepActive.value == 1)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                    key: 1,
                                    type: "primary",
                                    loading: (0, vue_2.unref)(loading),
                                    onClick: submit
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)("提交")
                                    ]; }),
                                    _: 1
                                }, 8, ["loading"]))
                                : (0, vue_2.createCommentVNode)("", true),
                            (stepActive.value == 0)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                                    key: 2,
                                    type: "primary",
                                    onClick: _cache[13] || (_cache[13] = function ($event) { return (stepActive.value = 1); })
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)("下一步")
                                    ]; }),
                                    _: 1
                                }))
                                : (0, vue_2.createCommentVNode)("", true)
                        ], 64))
                        : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_button, {
                            key: 1,
                            type: "primary",
                            onClick: _cache[14] || (_cache[14] = function ($event) { return (visible.value = false); })
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createTextVNode)("关闭")
                            ]; }),
                            _: 1
                        }))
                ]; }),
                default: (0, vue_2.withCtx)(function () { return [
                    (modelType.value == 'audit')
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_steps, {
                            key: 0,
                            style: { "width": "100%" },
                            active: stepActive.value,
                            "align-center": ""
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(_component_el_step, { title: "基础信息" }),
                                (0, vue_2.createVNode)(_component_el_step, { title: "审核" })
                            ]; }),
                            _: 1
                        }, 8, ["active"]))
                        : (0, vue_2.createCommentVNode)("", true),
                    (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                        (stepActive.value == 0)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                                (0, vue_2.createVNode)(_component_el_descriptions, {
                                    column: 2,
                                    border: ""
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "真实姓名" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_text, {
                                                    modelValue: info.value.realname,
                                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((info.value.realname) = $event); })
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "昵称" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_text, {
                                                    modelValue: info.value.nickname,
                                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((info.value.nickname) = $event); })
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "所属单位" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_text, {
                                                    modelValue: info.value.qq,
                                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((info.value.qq) = $event); })
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "所属科室" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_text, {
                                                    modelValue: info.value.works,
                                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((info.value.works) = $event); })
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "擅长领域" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_text, {
                                                    modelValue: info.value.intro,
                                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((info.value.intro) = $event); })
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "工作年限" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_text, {
                                                    modelValue: info.value.ability,
                                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((info.value.ability) = $event); })
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, {
                                            span: 2,
                                            label: "审核状态"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_h_dict, {
                                                    modelValue: info.value.status,
                                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return ((info.value.status) = $event); }),
                                                    mode: "text",
                                                    dictKey: "audit_type"
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (info.value.memo)
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_descriptions_item, {
                                                key: 0,
                                                span: 2,
                                                label: "审核意见"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(info.value.memo), 1)
                                                ]; }),
                                                _: 1
                                            }))
                                            : (0, vue_2.createCommentVNode)("", true),
                                        (0, vue_2.createVNode)(_component_el_descriptions_item, { label: "审核图片" }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (auditImages.value.length > 0)
                                                    ? ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, (0, vue_2.renderList)(auditImages.value, function (item, index) {
                                                        return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_image, {
                                                            fit: "contain",
                                                            key: index,
                                                            class: "w-100 h-100 m-r-5",
                                                            "preview-src-list": auditImages.value,
                                                            src: item
                                                        }, null, 8, ["preview-src-list", "src"]));
                                                    }), 128))
                                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_3, "暂无内容"))
                                            ]; }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                })
                            ]))
                            : (0, vue_2.createCommentVNode)("", true),
                        (stepActive.value == 1)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_4, [
                                (0, vue_2.createVNode)(_component_el_form, {
                                    ref_key: "formRef",
                                    ref: formRef,
                                    "label-width": "auto",
                                    model: model.value,
                                    rules: rules.value
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createVNode)(_component_el_form_item, {
                                            label: "关联项目",
                                            prop: "projectId"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_el_select, {
                                                    onChange: selectEvent,
                                                    modelValue: model.value.projectId,
                                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) { return ((model.value.projectId) = $event); }),
                                                    placeholder: "请选择项目"
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(projectList.value, function (item, index) {
                                                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_option, {
                                                                label: item.project_name,
                                                                value: item.id,
                                                                key: index
                                                            }, null, 8, ["label", "value"]));
                                                        }), 128))
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (model.value.projectId)
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_form_item, {
                                                key: 0,
                                                label: "中心",
                                                prop: "teamId"
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createVNode)(_component_el_select, {
                                                        modelValue: model.value.teamId,
                                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) { return ((model.value.teamId) = $event); }),
                                                        placeholder: "请选择中心"
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(teamList.value, function (item, index) {
                                                                return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_el_option, {
                                                                    label: item.name,
                                                                    value: item.id,
                                                                    key: index
                                                                }, null, 8, ["label", "value"]));
                                                            }), 128))
                                                        ]; }),
                                                        _: 1
                                                    }, 8, ["modelValue"])
                                                ]; }),
                                                _: 1
                                            }))
                                            : (0, vue_2.createCommentVNode)("", true),
                                        (0, vue_2.createVNode)(_component_el_form_item, {
                                            label: "审核",
                                            prop: "status"
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_el_radio_group, {
                                                    modelValue: model.value.status,
                                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) { return ((model.value.status) = $event); })
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createVNode)(_component_h_dict, { dictKey: "audit_type_edit" })
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }),
                                        (0, vue_2.createVNode)(_component_el_form_item, {
                                            ref_key: "reasonRef",
                                            ref: reasonRef,
                                            label: "审核意见",
                                            prop: "reason",
                                            rules: {
                                                required: model.value.status == 'rejected' ? true : false,
                                                message: '请输入审核意见',
                                                trigger: ['blur', 'change'],
                                            }
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createVNode)(_component_el_input, {
                                                    type: "textarea",
                                                    modelValue: model.value.reason,
                                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) { return ((model.value.reason) = $event); }),
                                                    rows: "4",
                                                    placeholder: "请输入审核意见"
                                                }, null, 8, ["modelValue"])
                                            ]; }),
                                            _: 1
                                        }, 8, ["rules"])
                                    ]; }),
                                    _: 1
                                }, 8, ["model", "rules"])
                            ]))
                            : (0, vue_2.createCommentVNode)("", true)
                    ])), [
                        [_directive_loading, (0, vue_2.unref)(loading)]
                    ])
                ]; }),
                _: 1
            }, 8, ["title", "modelValue"]));
        };
    }
});
